<!-- auth-user -->
<template>
  <div class="auth-user">
    <!-- 分组管理 -->
    <div class="user-group-container">
      <user-group @on-group-updated="handleGroupUpdated" />
    </div>

    <!-- 用户管理 -->
    <div class="user-man-container">
      <user-man ref="user" />
    </div>
  </div>
</template>

<script>
  import UserGroup from './user-group';
  import UserMan from './user-man';

  export default {
    name: 'auth-user',
    components: {
      UserGroup,
      UserMan,
    },
    methods: {
      // 分组更新(编辑)完成，刷新用户列表以同步更新后的分组名称
      handleGroupUpdated() {
        this.$refs.user.getTableData();
      },
    },
  };
</script>

<style scoped lang="scss">
  .auth-user {
    display: flex;
    flex-grow: 1;

    // 分组管理
    .user-group-container {
      flex: 0 0 320px;
      border-radius: 3px;
      padding: 5px 8px;
      margin-top: -5px;
      background-color: #fbfcfe;
      box-shadow: 2px 0 8px 0 rgba(29, 35, 41, 0.05);
    }

    // 用户管理
    .user-man-container {
      flex-grow: 1;
      margin-left: 20px;
    }
  }
</style>
