<!-- user-man -->
<template>
  <div class="user-man">
    <!-- 查询表单 -->
    <div class="user-man-form">
      <el-form :model="model" inline size="small" @submit.native.prevent>
        <el-form-item label="角色:">
          <role v-model="model.roleId" />
        </el-form-item>

        <el-form-item label="状态:">
          <user-status v-model="model.status" />
        </el-form-item>

        <el-form-item label="搜索:">
          <el-input
            v-model.trim="model.keyword"
            placeholder="用户名、手机号"
            clearable
            @keyup.native.enter="handleFormSearchClick"
          />
        </el-form-item>

        <el-form-item>
          <el-button
            type="primary"
            icon="el-icon-search"
            :loading="searching"
            @click="handleFormSearchClick"
            >查询
          </el-button>

          <el-button icon="el-icon-refresh" @click="handleFormResetClick"
            >重置
          </el-button>
        </el-form-item>
      </el-form>
    </div>

    <!-- 操作栏 -->
    <div class="user-man-operate">
      <el-button
        v-if="whetherRenderOperateAdd"
        v-disable:[operateAddDisabledTips]="!groupId"
        type="primary"
        icon="el-icon-plus"
        size="small"
        @click="handleOperateAddClick"
        >新增
      </el-button>

      <el-button
        type="danger"
        icon="el-icon-delete"
        size="small"
        :disabled="!removeIds.length"
        @click="handleOperateRemoveClick"
        >删除
      </el-button>
    </div>

    <!-- 数据表格 -->
    <div class="user-man-table">
      <el-table
        v-loading="loading"
        ref="table"
        :data="users"
        size="small"
        @selection-change="handleTableSelectionChange"
      >
        <el-table-column type="selection" width="55" />

        <el-table-column label="用户名" prop="name" />

        <el-table-column label="手机号" prop="telephone" />

        <el-table-column label="角色" prop="roleName" />

        <el-table-column label="分组" prop="groupName" />

        <el-table-column label="状态">
          <template v-slot="{ row: { status } }">
            <el-tag :type="status | userStatusTagClass" size="mini">
              {{ status | userStatusText }}
            </el-tag>
          </template>
        </el-table-column>

        <el-table-column label="创建时间" prop="createTime" />

        <el-table-column label="操作" width="90px">
          <template v-slot="{ row }">
            <div class="table-row-operate">
              <el-button
                plain
                icon="el-icon-edit"
                circle
                size="mini"
                @click="handleTableRowEditClick(row)"
              />

              <el-button
                plain
                type="danger"
                icon="el-icon-delete"
                circle
                size="mini"
                @click="handleTableRowRemoveClick(row)"
              />
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!-- 分页器 -->
    <div class="user-man-pagination">
      <el-pagination
        :total="total"
        :current-page="pageNo"
        :page-sizes="pageSizes"
        background
        layout="total, sizes, prev, pager, next, jumper"
        @current-change="handlePageNoChange"
        @size-change="handlePageSizeChange"
      />
    </div>

    <!-- 新增/编辑对话框 -->
    <user-edit
      v-model="showEditDialog"
      :id="operateId"
      @on-ok="handleEditDialogOkClick"
    />

    <!-- 删除对话框 -->
    <user-remove
      v-model="showRemoveDialog"
      :ok-button-loading="removing"
      @on-close="handleRemoveDialogClose"
      @on-ok="handleRemoveDialogOkClick"
    />
  </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex';
  import Role from '../../../../components/role';
  import UserStatus from '../../../../components/user-status';
  import { pageSize, pageSizes } from '../../../../config/pagination';
  import recountPageNo from '../../../../lib/recount-page-no';
  import userStatus from '../../../../enum/user-status';
  import { removeUsers } from '../../../../api/auth/user';

  export default {
    name: 'user-man',
    components: {
      Role,
      UserStatus,
      UserEdit: () =>
        import(/* webpackChunkName: 'user-man-edit' */ './man-edit'),
      UserRemove: () =>
        import(
          /* webpackChunkName: 'user-man-remove' */ '../../../../components/confirm'
        ),
    },
    filters: {
      // 用户状态标签类名
      userStatusTagClass(val) {
        const classMap = {
          [userStatus.enum.enabled]: 'success',
          [userStatus.enum.disabled]: 'danger',
          [userStatus.enum.waiting]: 'warning',
        };

        return classMap[val];
      },
      // 用户状态文案
      userStatusText(val) {
        return userStatus.map[val];
      },
    },
    data() {
      return {
        // 是否渲染操作栏新增按钮，用于重新渲染以重新执行 v-disable 指令
        whetherRenderOperateAdd: true,
        model: null,
        pageNo: 1,
        pageSize,
        pageSizes,
        operateId: '',
        showEditDialog: false,
        removeIds: [],
        showRemoveDialog: false,
        loading: false,
        searching: false,
        removing: false,
      };
    },
    computed: {
      ...mapState('auth/user', ['groupId', 'users', 'total']),

      // 用户状态枚举值
      userStatusEnum() {
        return userStatus.enum;
      },
      // 操作栏新增按钮禁用提示
      operateAddDisabledTips() {
        return '请先从左侧列表中选择一个【分组】';
      },
    },
    created() {
      this.initModel();
    },
    mounted() {
      this.getTableData();
    },
    methods: {
      ...mapActions('auth/user', ['actUsers']),

      // 初始化模型
      initModel() {
        this.model = {
          roleId: '',
          status: '',
          keyword: '',
        };
      },
      // 重新渲染操作栏新增按钮
      async rerenderOperateAdd() {
        this.whetherRenderOperateAdd = false;

        await this.$nextTick();

        this.whetherRenderOperateAdd = true;
      },
      // 获取表格数据
      async getTableData() {
        this.loading = true;

        const success = await this.actUsers({
          ...this.model,
          groupId: this.groupId,
          pageNo: this.pageNo,
          pageSize: this.pageSize,
        });

        this.loading = false;

        return success;
      },
      // 删除表格数据
      async removeTableData() {
        const ids = this.removeIds;

        if (!ids.length) return;

        this.removing = true;

        const res = await removeUsers(ids);

        this.removing = false;

        return !!res;
      },
      // 表单查询按钮单击
      async handleFormSearchClick() {
        this.pageNo = 1;
        this.searching = true;

        await this.getTableData();

        this.searching = false;
      },
      // 表单重置按钮单击
      handleFormResetClick() {
        this.pageNo = 1;

        this.initModel();
        this.getTableData();
      },
      // 操作栏新增按钮单击
      handleOperateAddClick() {
        this.operateId = '';
        this.showEditDialog = true;
      },
      // 操作栏删除按钮单击
      handleOperateRemoveClick() {
        this.showRemoveDialog = true;
      },
      // 表格选择项改变
      handleTableSelectionChange(rows) {
        this.removeIds = rows.map(row => row._id);
      },
      // 表格行编辑按钮单击
      handleTableRowEditClick({ _id }) {
        this.operateId = _id;
        this.showEditDialog = true;
      },
      // 表格行删除按钮单击
      handleTableRowRemoveClick({ _id }) {
        this.removeIds = [_id];
        this.showRemoveDialog = true;
      },
      // 新增/编辑对话框确定按钮执行成功
      handleEditDialogOkClick() {
        this.getTableData();
      },
      // 删除对话框关闭
      handleRemoveDialogClose() {
        this.removeIds = [];

        this.$refs.table.clearSelection();
      },
      // 删除对话框确定按钮单击
      async handleRemoveDialogOkClick() {
        const success = await this.removeTableData();

        if (!success) return;

        this.showRemoveDialog = false;
        this.pageNo = recountPageNo(
          this.total,
          this.pageNo,
          this.pageSize,
          this.removeIds.length
        );

        this.getTableData();
      },
      // 分页器页码改变
      handlePageNoChange(pageNo) {
        this.pageNo = pageNo;

        this.getTableData();
      },
      // 分页器条目数改变
      handlePageSizeChange(pageSize) {
        this.pageSize = pageSize;

        this.getTableData();
      },
    },
    watch: {
      groupId() {
        this.getTableData();
        this.rerenderOperateAdd();
      },
    },
  };
</script>

<style scoped lang="scss">
  .user-man {
    // 数据表格
    .user-man-table {
      margin-top: 20px;

      // 操作按钮
      .table-row-operate {
        .el-button {
          min-width: auto;
        }
      }
    }

    // 分页器
    .user-man-pagination {
      margin-top: 20px;
    }
  }
</style>
