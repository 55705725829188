<!-- user-group -->
<template>
  <div class="user-group">
    <!-- 查询表单 -->
    <div class="user-group-form">
      <el-form :model="model" inline size="small" @submit.native.prevent>
        <el-form-item class="form-search">
          <el-input
            class="form-search-input"
            v-model.trim="model.keyword"
            placeholder="输入分组名搜索"
            clearable
            @input="handleFormSearchClick"
            @keyup.native.enter="handleFormSearchClick"
          />
        </el-form-item>
      </el-form>
    </div>

    <!-- 操作栏 -->
    <div class="user-group-operate">
      <el-button
        plain
        type="primary"
        icon="el-icon-plus"
        size="small"
        @click="handleOperateAddClick"
        >新增
      </el-button>

      <el-button
        plain
        type="danger"
        icon="el-icon-delete"
        size="small"
        :disabled="!removeIds.length"
        @click="handleOperateRemoveClick"
        >删除
      </el-button>
    </div>

    <!-- 分组树容器 -->
    <div class="user-group-tree-container" v-loading="loading">
      <!-- 分组树空占位符 -->
      <empty-placeholder v-if="!groupTree || !groupTree.length">
        <div class="user-group-placeholder">
          <span>暂无数据，请先</span>

          <el-button
            class="placeholder-btn"
            type="text"
            @click="handleGroupPlaceholderBtnClick"
            >设置分组</el-button
          >
        </div>
      </empty-placeholder>

      <!-- 分组树 -->
      <el-tree
        v-else
        class="user-group-tree"
        ref="tree"
        :data="groupTree"
        :props="treeOptions"
        node-key="_id"
        show-checkbox
        check-strictly
        highlight-current
        default-expand-all
        :expand-on-click-node="false"
        :filter-node-method="groupTreeFilterNodeFn"
        @node-click="handleGroupTreeNodeClick"
        @check-change="handleGroupTreeNodeCheckChange"
      >
        <template slot-scope="{ data: nodeData }">
          <div class="tree-node" :class="{ active: nodeData._id === groupId }">
            <div class="tree-node-name">
              {{ nodeData.name }}
            </div>

            <div class="tree-node-operate-container">
              <div class="tree-node-operate" @click.stop>
                <el-button
                  plain
                  icon="el-icon-edit"
                  circle
                  size="mini"
                  @click="handleGroupTreeNodeEditClick(nodeData)"
                />

                <el-button
                  plain
                  type="danger"
                  icon="el-icon-delete"
                  circle
                  size="mini"
                  @click="handleGroupTreeNodeRemoveClick(nodeData)"
                />
              </div>
            </div>
          </div>
        </template>
      </el-tree>
    </div>

    <!-- 新增/编辑对话框 -->
    <group-edit
      v-model="showEditDialog"
      :id="operateId"
      @on-ok="handleEditDialogOkClick"
    />

    <!-- 删除对话框 -->
    <group-remove
      v-model="showRemoveDialog"
      :ok-button-loading="removing"
      @on-close="handleRemoveDialogClose"
      @on-ok="handleRemoveDialogOkClick"
    />
  </div>
</template>

<script>
  import { mapState, mapMutations, mapActions } from 'vuex';
  import EmptyPlaceholder from '../../../../components/empty-placeholder';
  import genGroupTree from '../../../../lib/gen-group-tree';
  import { removeGroups } from '../../../../api/auth/user';

  export default {
    name: 'user-group',
    components: {
      EmptyPlaceholder,
      GroupEdit: () =>
        import(/* webpackChunkName: 'user-group-edit' */ './group-edit'),
      GroupRemove: () =>
        import(
          /* webpackChunkName: 'user-group-remove' */ '../../../../components/confirm'
        ),
    },
    data() {
      return {
        model: null,
        operateId: '',
        showEditDialog: false,
        removeIds: [],
        showRemoveDialog: false,
        loading: false,
        removing: false,
      };
    },
    computed: {
      ...mapState('auth/user', ['groups', 'groupId']),

      // 分组树配置选项
      treeOptions() {
        return {
          label: 'name',
          children: 'children',
        };
      },

      // 分组树
      groupTree() {
        return genGroupTree(this.groups);
      },
    },
    created() {
      this.initModel();
    },
    mounted() {
      this.getTreeData();
    },
    beforeDestroy() {
      this.mttGroupId();
    },
    methods: {
      ...mapMutations('auth/user', ['mttGroupId']),
      ...mapActions('auth/user', ['actGroups']),

      // 初始化模型
      initModel() {
        this.model = {
          keyword: '',
        };
      },
      // 分组树过滤节点的函数
      groupTreeFilterNodeFn(value, data) {
        if (!value) return true;

        const { name = '' } = data || {};

        return name.includes(value);
      },
      // 获取分组树(分组)数据
      async getTreeData() {
        this.loading = true;

        const success = await this.actGroups();

        this.loading = false;

        return success;
      },
      // 删除分组树(分组)数据
      async removeTreeData() {
        const ids = this.removeIds;

        if (!ids.length) return;

        this.removing = true;

        const res = await removeGroups(ids);

        this.removing = false;

        return !!res;
      },
      // 表单查询按钮单击
      handleFormSearchClick(val) {
        this.$refs.tree.filter(val);
      },
      // 操作栏新增按钮单击
      handleOperateAddClick() {
        this.operateId = '';
        this.showEditDialog = true;
      },
      // 操作栏删除按钮单击
      handleOperateRemoveClick() {
        this.showRemoveDialog = true;
      },
      // 分组树节点项单击
      handleGroupTreeNodeClick(nodeData) {
        const { _id } = nodeData;
        // 可反选节点项，以便查看全部用户
        const groupId = _id === this.groupId ? '' : _id;

        this.mttGroupId(groupId);
      },
      // 分组树节点项选中状态改变
      handleGroupTreeNodeCheckChange(nodeData, isChecked) {
        const { _id } = nodeData;
        const removeIds = this.removeIds;
        const matchedIndex = removeIds.findIndex(id => id === _id);

        // 选中
        if (isChecked) {
          matchedIndex < 0 && removeIds.push(_id);

          return;
        }

        matchedIndex > -1 && removeIds.splice(matchedIndex, 1);
      },
      // 分组树节点项编辑按钮单击
      handleGroupTreeNodeEditClick(nodeData) {
        const { _id } = nodeData;

        this.operateId = _id;
        this.showEditDialog = true;
      },
      // 分组树节点项删除按钮单击
      handleGroupTreeNodeRemoveClick(nodeData) {
        const { _id } = nodeData;

        this.removeIds = [_id];
        this.showRemoveDialog = true;
      },
      // 新增/编辑对话框确定按钮执行成功
      async handleEditDialogOkClick() {
        this.getTreeData();

        // 需要刷新用户列表以同步更新后的分组名称
        this.$emit('on-group-updated');
      },
      // 删除对话框关闭
      handleRemoveDialogClose() {
        this.removeIds = [];

        this.$refs.tree.setCheckedNodes([]);
      },
      // 删除对话框确定按钮单击
      async handleRemoveDialogOkClick() {
        const success = await this.removeTreeData();

        if (!success) return;

        this.showRemoveDialog = false;
        this.getTreeData();
      },
      // 分组树空占位符按钮单击
      handleGroupPlaceholderBtnClick() {
        this.handleOperateAddClick();
      },
    },
  };
</script>

<style scoped lang="scss">
  @import '../../../../style/mixin.scss';

  .user-group {
    display: flex;
    flex-direction: column;
    height: 100%;

    // 查询表单
    .user-group-form /deep/ .form-search {
      width: 100%;
      margin-right: 0;

      .el-form-item__content {
        width: 100%;

        .form-search-input {
          width: 100%;
        }
      }
    }

    // 分组树容器
    .user-group-tree-container {
      display: flex;
      flex-grow: 1;
      margin-top: 20px;

      // 分组树空占位符
      .user-group-placeholder .placeholder-btn {
        min-width: auto;
        font-size: 13px;
      }

      // 分组树
      .user-group-tree {
        flex-grow: 1;
        font-size: 13px;
        background-color: transparent;

        /deep/ .el-tree-node {
          .el-tree-node__content {
            height: auto;

            .tree-node {
              display: flex;
              align-items: center;
              flex-grow: 1;
              height: 38px;

              .tree-node-name {
                @include ellipsis();

                flex-grow: 1;
                transition: color 300ms;
              }

              .tree-node-operate-container {
                display: flex;
                align-items: center;
                flex: 0 0 75px;

                .tree-node-operate {
                  display: flex;
                  width: 0;
                  overflow: hidden;
                  opacity: 0;
                  transition: opacity 0.5s ease-in-out;

                  .el-button {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    min-width: auto;
                    width: 25px;
                    height: 25px;
                  }
                }
              }

              &.active .tree-node-name {
                color: #409eff;
              }
            }

            &:hover .tree-node-operate-container .tree-node-operate {
              width: 100%;
              opacity: 1;
            }
          }
        }
      }
    }
  }
</style>
