// 用户状态
export default {
  map: {
    1: '已启用',
    2: '已禁用',
    3: '待确认',
  },
  enum: {
    enabled: 1,
    disabled: 2,
    waiting: 3,
  },
};
