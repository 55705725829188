// 根据分组列表生成分组树(树形结构)
const genGroupTree = (groupList, groupTree = []) => {
  if (!Array.isArray(groupList) || !groupList.length) return groupTree;

  const clonedGroupList = JSON.parse(JSON.stringify(groupList));

  clonedGroupList.forEach(group => {
    const { pid } = group;

    if (!pid) {
      groupTree.push(group);

      return;
    }

    const pGroup = clonedGroupList.find(group => group._id === pid);

    if (!pGroup) return;

    const { children = [] } = pGroup;

    pGroup.children = [...children, group];
  });

  return groupTree;
};

export default genGroupTree;
