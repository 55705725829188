<!-- role -->
<template>
  <el-select
    class="role"
    v-model="selected"
    :placeholder="placeholder"
    :disabled="disabled"
    :clearable="clearable"
    :filterable="filterable"
    :filter-method="filterMethod"
    :default-first-option="defaultFirstOption"
    :size="size"
    @change="handleValueChange"
    @clear="handleValueClear"
  >
    <el-option
      v-for="item in list"
      :label="item[labelKey]"
      :value="item[valueKey]"
      :key="item[valueKey]"
    />
  </el-select>
</template>

<script>
  import select from '../../mixins/select';
  import { getRoles } from '../../api/auth/role';

  export default {
    name: 'role',
    mixins: [select],
    mounted() {
      this.getRoles();
    },
    methods: {
      async getRoles() {
        const params = { pageSize: 0 };
        const res = await getRoles(params);

        if (!res) return;

        const { data: { list: roles = [] } = {} } = res;

        this.list = roles.map(item => {
          const { _id, name } = item;

          return {
            [this.labelKey]: name,
            [this.valueKey]: _id,
          };
        });
      },
    },
  };
</script>
